.quote-section {
	color: var(--color-brand-tertiary);
	margin: var(--space-l2) 0;

	@media (--width-l2) {
		margin-top: var(--space-l4);
		margin-bottom: var(--space-l4);
	}
	
	.container {
		background-color: var(--background-color-dark3);
		position: relative;

		@media (--width-l3) {
			display: flex;
			flex-direction: row-reverse;
			justify-content: space-around;
			align-items: center;
			padding: var(--space-l3) 0;
		}

		&::before {
			content: '';
			z-index: -1;
			top: -1rem;
			bottom: -1rem;
			left: 0;
			right: 0;
			position: absolute;
			display: block;
			background: url('/assets/images/bg-texture-2.png') rgba(91, 178, 229, 0.3) repeat;

			@media (--width-l2) {
				top: -3rem;
				bottom: -3rem;
			}

			@media (--width-l3) {
				left: 3rem;
				right: 3rem;
			}
		}
	}

	blockquote {
		padding: 0;
		margin: 0;

		p {
			font-size: 1.5em;
			margin: 0 0 var(--space-l2);
		}
	}

	.section-text { 
		padding: var(--space-l2) 0;

		@media (--width-l2) {
			max-width: 33rem;
			margin: 0 var(--space-l3);
		}

		@media (--width-l3) {
			max-width: 40rem;
			margin-left: var(--space-l4);
			margin-right: var(--space-l4);
		}

		footer {
			font-weight: bold;
		}
	}

	.section-image {
		width: 100vw;
		position: relative;
		left: 50%;
		right: 50%;
		margin-left: -50vw;
		margin-right: -50vw;

		@media (--width-l2) {
			width: auto;
			position: static;
			left: 0;
			right: 0;
			margin-left: auto;
			margin-right: auto;
		}

		@media (--width-l3) {
			transform: translateX(-5rem);
		}

		img {
			display: block;
			width: 100%;
			height: auto;

			@media (--width-l2) {
				width: auto;
			}
		}
	}
}