@import 'variables.css';
@import 'partials/quote-section.css';

/* Page Content */
.hero-section {
	max-width: var(--section-max-width);
	background-color: var(--background-color-light3);
	margin: 0 auto;

	.container {
		@media (--width-l2) {
			display: flex;
			padding-left: 0;
			padding-right: 0;
		}
		
		
		@media (--width-l3) {
			max-width: none;
		}
	}
}

.hero-text-container {
	display: inline-flex;
	align-items: center;
	flex: 1 0 auto;
	padding: var(--space-l3) 0;
	margin-bottom: var(--space-l2);

	@media (--width-l2) {
		padding-left: var(--space-l1);
		padding-right: var(--space-l1);
		margin-bottom: 0;
		margin-right: 0;
	}

	@media (--width-l3) {
		flex-basis: 50%;
		flex-grow: 1;
	}
}

.hero-text {
	max-width: 30rem;
	margin: 0 auto;

	@media (--width-l3) {
		max-width: 25rem;
	}

	h1 {
		margin-top: 0;
		margin-bottom: 0;
	}

	p {
		line-height: 1.85;
		margin-bottom: var(--space-l2);

		@media (--width-l3) {
			margin-bottom: var(--space-l3);
		}

		> strong {
			font-weight: inherit;
			text-decoration: underline;
		}
	}

	a.navigation-button {
		text-transform: uppercase;
	}
}

.hero-heading {
	display: inline-block;
	line-height: 0.9;
	font-size: 2.5rem;
	font-weight: 300;
	font-family: var(--font-fam-body);
	margin-bottom: var(--space-l2);

	a {
		color: #0A347A;
		text-decoration: none;
	}
}

.hero-image {
	display: inline-block;

	@media (--width-l3) {
		/* flex-basis: 50%; */
	}

	img {
		display: block;
		width: auto;
		height: auto;
		max-width: 100%;
	}
}

.why-section {
	max-width: var(--section-max-width);
	padding: var(--space-l3) 0 var(--space-l3);
	margin: 0 auto;
	
	.container {
		@media (--width-l2) {
			display: flex;
		}

		h2 {
			vertical-align: top;
			margin-top: 0;
			margin-bottom: var(--space-l2);
	
			@media (--width-l2) {
				display: inline-block;
				margin-bottom: 0;
				padding-right: var(--space-l3);
			}
		}

		p {
			margin: 0;

			@media (--width-l2) {
				display: inline-block;
				max-width: 40rem;
			}
		}

		@media (--width-l2) {
			h2 {
				text-align: right;
				flex-basis: 50%;
				padding-left: 0;
				padding-right: var(--space-l3);
			}
			
			p {
				flex-basis: 50%;
			}
		}
	}
}

.family-values-section {
	max-width: var(--section-max-width);
	background-color: var(--background-color-light2);
	padding: var(--space-l1) 0;
	margin: 0 auto var(--space-l3);

	@media (--width-l2) {
		padding: 0;
	}

	@media (--width-l2) {
		position: relative;
	}

	.section-text {
		margin-bottom: var(--space-l2);

		@media (--width-l2) {
			flex-basis: 50%;
			margin-bottom: 0;
			padding-right: var(--space-l3);

			h2 {
				margin-top: var(--space-l3);
			}

			p {
				max-width: 20rem;
			}
		}

		@media (--width-l3) {
			padding-right: var(--space-l4);
		}
	}

	.container {
		@media (--width-l2) {
			display: flex;
			position: relative;
		}
	}

	.section-image img {
		display: block;
		height: auto;
		max-width: 100%;
		background-color: var(--background-color-light3);
		padding: 2rem;
		margin: 2rem 0;
	}

	a.navigation-button {
		text-transform: uppercase;
	}
}

.quote-section {
	margin-top: 10rem;
}

.life-section {
	.life-section-opening p {
		max-width: 28.5rem;
		margin: var(--space-l2) 0 var(--space-l3);
	}

	blockquote {
		max-width: 35rem;
		padding: 0;
		margin: 0;

		footer {
			font-size: 0.85rem;
			font-weight: bold;

			.role {
				font-weight: 300;
				font-style: italic;
			}
		}
	}

	figure {
		padding: 0;
		margin: 0;
	}

	img {
		width: 100%;
		max-width: 100%;
		height: auto;

		@media (--width-l2) {
			width: auto;
		}
	}


	.life-section-row {
		.life-section-image {
			margin-bottom: var(--space-l2);

			@media (--width-l2) {
				&.portrait {
					max-width: 28rem;
				}

				&.landscape {
					max-width: 35rem;
				}
			}
		}

		blockquote {
			margin-bottom: var(--space-l2);
		}

		@media (--width-l2) {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	@media (--width-l2) {
		.life-section-image + .life-section-image {
			margin-left: var(--space-l2);
		}
	}
}

.veterans-section {
	padding: var(--space-l3) 0;
	
	.section-text {
		@media (--width-l3) {
			max-width: 18rem;
		}
	}

	img {
		max-width: 100%;
		height: auto;
	}

	.section-text {
		p {
			margin: 0 0 var(--space-l2);
		}

		@media (--width-l2) {
			align-self: flex-start;
			flex-basis: 100%;
		}
		
		@media (--width-l3) {
			flex-basis: auto;
		}
	}

	.section-image {
		margin-bottom: var(--space-l2);
		
		@media (--width-l2) {
			flex-basis: 50%;
		}

		@media (--width-l3) {
			flex-basis: auto;
			margin-bottom: 0;
			margin-left: var(--space-l2);
		}
	}

	.section-content {
		@media (--width-l2) {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-end;
			justify-content: space-between;
		}
		
		@media (--width-l3) {
			flex-wrap: nowrap;
		}
	}
}